import type { IHttpClient } from '@wix/yoshi-flow-editor';

import type {
  FollowersReadService as IFollowersReadService,
  GetFollowersResponse,
  GetFollowingFollowersResponse,
  GetFollowingResponse,
} from '../../../types/followers';

export class FollowersReadService implements IFollowersReadService {
  constructor(private readonly httpClient: IHttpClient) {}

  async getFollowingFollowers(
    memberId: string,
  ): Promise<GetFollowingFollowersResponse> {
    const { data } = await this.httpClient.get<GetFollowingFollowersResponse>(
      `/_serverless/following-followers-middleware/${memberId}`,
    );
    return data;
  }

  async getFollowers(
    memberId: string,
    cursor: string,
  ): Promise<GetFollowersResponse> {
    const { data } = await this.httpClient.get<GetFollowersResponse>(
      `/_serverless/following-followers-middleware/${memberId}/followers`,
      { params: { cursor } },
    );

    return data;
  }

  async getFollowing(
    memberId: string,
    cursor: string,
  ): Promise<GetFollowingResponse> {
    const { data } = await this.httpClient.get<GetFollowingResponse>(
      `/_serverless/following-followers-middleware/${memberId}/following`,
      { params: { cursor } },
    );

    return data;
  }
}
