import { currentUserServiceFactory } from '../../../services/current-user';
import { InstanceService } from '../../../services/instance-service';
import { MembersService } from '../../../services/members';
import { MembersAreaPublicAPI } from '../../../services/members-area-public-api';
import { MonitoringService } from '../../../services/monitoring';
import { MultiStateBoxService } from '../../../services/multi-state-box';
import { PageService } from '../../../services/page';
import { WarmupDataService } from '../../../services/warmup-data-service';
import type {
  CreateSettingsPageControllerParams,
  MenuItem,
  SettingsPageContextProps,
  SettingsPageContextServices,
} from '../../../types';
import { ErrorStateService } from '../services/error-state';
import { MenuService } from '../services/menu';
import { RouteData } from '../services/route-data';
import { WidgetPluginHostService } from '../services/widget-plugin-host';
import { getState } from './state';

type CreateContextPropsOptions = Pick<
  CreateSettingsPageControllerParams,
  'flowAPI' | 'controllerConfig' | '$bind' | '$w' | 'initState'
> & {
  contextServices: SettingsPageContextServices;
};

type CreateContextServicesOptions = Pick<
  CreateSettingsPageControllerParams,
  'flowAPI' | '$w' | 'controllerConfig'
>;

const createContextProps = ({
  flowAPI,
  controllerConfig: { wixCodeApi },
  contextServices,
  initState,
  $bind,
  $w,
}: CreateContextPropsOptions): SettingsPageContextProps => {
  const state = getState({
    flowAPI,
    contextServices,
    initState,
  });

  return {
    state,
    flowAPI,
    wixCodeApi,
    $w,
    bind: $bind,
  };
};

const createContextServices = ({
  flowAPI,
  controllerConfig,
  $w,
}: CreateContextServicesOptions): SettingsPageContextServices => {
  const menuItems = ($w('#horizontalMenu')?.menuItems ?? []) as MenuItem[];
  const monitoringService = new MonitoringService(
    flowAPI.fedops,
    flowAPI.errorMonitor,
    flowAPI.environment,
    flowAPI.controllerConfig,
  );
  const multiStateBoxService = new MultiStateBoxService($w);
  const routeDataService = new RouteData(
    controllerConfig.wixCodeApi.location,
    menuItems,
    flowAPI.environment.isEditor,
  );
  const widgetPluginHostService = new WidgetPluginHostService(
    $w,
    routeDataService,
  );
  const instanceService = new InstanceService(
    controllerConfig.appParams,
    controllerConfig.wixCodeApi.site,
  );
  const currentUserService = currentUserServiceFactory(
    flowAPI,
    instanceService,
  );
  const pageService = new PageService(controllerConfig.wixCodeApi.site);
  const membersAreaPublicAPI = new MembersAreaPublicAPI(
    controllerConfig.wixCodeApi.site.getPublicAPI,
  );
  const membersService = new MembersService(flowAPI.httpClient);
  const warmupDataService = new WarmupDataService(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
  );
  const menuService = new MenuService(menuItems);
  const errorStateService = new ErrorStateService($w, flowAPI.translations.t);

  return {
    monitoringService,
    multiStateBoxService,
    routeDataService,
    widgetPluginHostService,
    currentUserService,
    pageService,
    membersAreaPublicAPI,
    membersService,
    warmupDataService,
    menuService,
    errorStateService,
  };
};

export const getContext = (options: CreateSettingsPageControllerParams) => {
  const contextServices = createContextServices(options);
  const contextProps = createContextProps({ ...options, contextServices });

  return { contextProps, contextServices };
};
