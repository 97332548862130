import { getRoles } from '@wix/ambassador-members-v1-role/http';
import type { IHttpClient } from '@wix/yoshi-flow-editor';

import type { Nullable, RolesService as IRolesService } from '../../../types';

export class RolesService implements IRolesService {
  constructor(private httpClient: IHttpClient) {}
  async getMemberRoles(memberId: Nullable<string>) {
    if (!memberId) {
      return [];
    }

    const { data } = await this.httpClient.request(getRoles({ memberId }));

    return data.roles?.map((role) => role.roleKey?.toLocaleLowerCase()!) || [];
  }
}
