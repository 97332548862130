import { MembersService } from '../../services/members';
import type { FlowAPI, InitState } from '../../types';
import type {
  FFModalContext,
  FFModalContextServices,
} from '../../types/followers';
import { FollowersReadService } from './services/followers-read';
import { FollowersWriteService } from './services/followers-write';
import { createState } from './services/state';

const createServices = (flowAPI: FlowAPI): FFModalContextServices => {
  const followersReadService = new FollowersReadService(flowAPI.httpClient);
  const followersWriteService = new FollowersWriteService(flowAPI.httpClient);
  const membersService = new MembersService(flowAPI.httpClient);

  return { followersReadService, followersWriteService, membersService };
};

type CreateContextOptions = {
  flowAPI: FlowAPI;
  initState: InitState;
};

export const createContext = ({
  flowAPI,
  initState,
}: CreateContextOptions): FFModalContext => {
  const services = createServices(flowAPI);
  const state = createState(initState, services);

  const wixCodeAPI = flowAPI.controllerConfig.wixCodeApi;
  const lightboxContext = wixCodeAPI.window.lightbox.getContext();

  return { state, lightboxContext };
};
